import "./styles.scss"

import React from "react"

import Button from "components/Button"

const Content = () => {
  return (
    <section className="not-found-content">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <span>#404</span>
            <h1>Ta strona nie istnieje</h1>
            <Button to="/" className="button--bordered">
              Strona główna
            </Button>
          </div>

          <div className="col-lg-6">
            <img
              className="img-fluid"
              src={require("assets/images/fox-search.png").default}
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Content
