import React from "react"

import Layout from "components/Layout"

import { Content } from "page_components/not_found"

const NotFoundPage = () => (
  <Layout
    seo={{
      title: "404",
      description: "",
    }}
  >
    <Content />
  </Layout>
)

export default NotFoundPage
